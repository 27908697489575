@import '../../assets/styles/vars';

.home-layout{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    main{
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 750px;
      flex: 1;
      padding: 32px 40px;

      @media screen and (max-width: $smWidthBreakpoint) {
        padding: 20px;
      }
    }
}

.settings-card {
  padding: 20px 0px;
  background-color: transparent;
  margin: 20px 0px;
}

.save {
  border-radius: 10px;
  box-shadow: none;
  color: white;
  font-weight: 700;
  padding-left: 32px;
  padding-right: 32px;
}

.settings-cell {
  width: 100%;
  padding: 10px 0px;
  margin: 1px 0px;

  .interaction {
    margin: 10px 0px;
  }
}

.modal-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  max-width: 500px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pending-cell {
  border: 1px solid #e4e4e6;
  padding: 16px 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.explore-button {
  padding: 10px 20px;
}

.pending-attr {

  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;

  svg {
    path {
      fill: #366778 ;
    }
  }

  .label {
    color: #6e6e8a;
    font-weight: 600;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;


  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items:center;
  gap: 16px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}