@import '../../assets/styles/vars';

.home-layout{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px 24px;

    main{
        height: 100%;
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.button-controller{
    justify-content: center;
    gap: 20px;
}

.center-aligner{
    flex: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

}

.input-controller{
    padding: 18px 16px;
    width: fit-content;
    background: $dark;
    box-shadow: $shadow;
    border-radius: 14px;
    position: relative;
    height: fit-content;

    align-items: flex-start;
    display: flex;
    gap: 20px;
    flex-direction: column;

    @media screen and (max-width:600px ) {
        width: auto !important;
    }

    input {
        width: 280px;

        background: #252525;
        border: 2px solid rgba(113, 113, 113, 0.2);
        box-shadow: $shadow;
        border-radius: 8px;
    }

    .check-btn {
        width: 170px;
        max-width: 182px;
        padding: 16px 40px;
        background: $dark-grey;
        box-shadow: $shadow;
        border-radius: 8px;
        color: white;

        @media screen and (max-width: 600px ) {
            max-width: 100%;
            width: 100%;
        }
    }
}

.claiming-button{
    height: 64px;
    padding: 10px 60px;
    font-size: 18px;
    font-weight: 400;

    @media screen and (max-width:900px ) {
        width: 100%;
    }
}

.modal-view{
    background-color: #272727;
    width: 400px;
    padding: 32px;
    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 20px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button{
    p {
        font-size: 25px !important;

    }
}

.result-controller{
    padding: 21px 24px;
    width: 100%;
    height: 60px;
    background: rgba(#000, .25);
    border: 1px solid rgba(113, 113, 113, 0.2);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.placeholder{
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0%, -50%);
}

.input-container{
    position: relative;
}


.input-container>label {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    // display: none;
    z-index: 1;

    @media screen and (max-width: 600px) {
        position: absolute;
        left: 16px;
        top: 23%;
    }
}

.input-container>input[type=text]:placeholder-shown+label {
    display: block;
}

.label{
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.center-trade {
    padding: 0;
    margin-top: -10px;
    margin-bottom: -20px !important;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: #366778; /* Background color: #6bc5d2 */
    margin-top: 10px;
  }
  
.explore-button {
    font-size: 1rem;
    width: 200px;
    color: white;
  }

  .card {
    background: #413f3f;
    border-radius: 2px;
    display: inline-block;
    height: 300px;
    margin: 1rem;
    position: relative;
    width: 300px;
  }
  
  .card-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }